<template>
    <div class="MultipleLZBaccarat allw flex column border-box pointer">
        <div class="flex1 backgroundWhite lz flex relative">
            <!--              <span :class="{'spancircle1':CopyTableInfo.betTime > 0}"-->
            <!--                    class="absolute colorccc font35 position-center zindex3">-->
            <!--				  {{CopyTableInfo.betTime <= 0 ? GetGameStatus(CopyTableInfo) : CopyTableInfo.betTime}}-->
            <!--              </span>-->
            <div ref="left" class="left-box relative">
                <ZhuzaiRoad :LeftH="LeftH" :RoadList="RoadList" :DefaultCount="4" :ImgRootUrl="ImgRootUrl"></ZhuzaiRoad>
            </div>
            <div class="flex1 flex column right-box relative">
                <div ref="rightTop" class="right-top relative">
                    <MainRoad :RightTopH="RightTopH" :RoadList="RoadList" :DefaultCount="10"
                              :ImgRootUrl="ImgRootUrl"></MainRoad>
                </div>
                <div class="rightBottom flex1 flex">
                    <div class="bottomLeft flex column">
                        <div ref="leftBottomTop" class="bottomTop relative flex1">
                            <PathRoad :LeftBottomTop="LeftBottomTop" :RoadList="RoadList" :DefaultCount="10"
                                      :ImgRootUrl="ImgRootUrl"></PathRoad>
                        </div>
                        <div ref="leftBottomBottom" class="bottomBottom flex1 relative">
                            <YueyouRoad :LeftBottomBottom="LeftBottomTop" :RoadList="RoadList"
                                        :DefaultCount="10" :ImgRootUrl="ImgRootUrl"></YueyouRoad>
                        </div>
                    </div>
                    <div class="bottomRight flex1 flex column">
                        <div ref="RightBottomTop" class="bottomTop relative flex1">
                            <ObliqueRoad :RightBottomTop="LeftBottomTop" :RoadList="RoadList"
                                         :DefaultCount="10" :ImgRootUrl="ImgRootUrl"></ObliqueRoad>
                        </div>
                        <div ref="RightBottomBottom" class="bottomBottom relative flex1">
                            <NumRoad :RightBottomBottom="LeftBottomTop" :RoadList="RoadList"
                                     :DefaultCount="4" :ImgRootUrl="ImgRootUrl"></NumRoad>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "MultipleLZBaccarat",
        computed: {
            ...mapState(['whdef'])
        },
        props: {
            'TableInfo': {
                default() {
                    return {}
                }, type: Object
            }
        },
        components: {
            ZhuzaiRoad: () => import('../Canvas/ZhuzaiRoad'),
            MainRoad: () => import('../Canvas/MainRoad'),
            PathRoad: () => import('../Canvas/PathRoad'),
            YueyouRoad: () => import('../Canvas/YueyouRoad'),
            ObliqueRoad: () => import('../Canvas/ObliqueRoad'),
            NumRoad: () => import('../Canvas/NumRoad'),
        },
        data() {
            return {
                CopyWhdef: 0,   //用于判断当前高度和未来高度是否相等
                LeftH: 0,  //猪仔路高度
                RightTopH: 0,  //大路高度
                LeftBottomTop: 0,  //小路高度  统一采用此高度
                // LeftBottomBottom: 0, //曰有高度
                // RightBottomTop: 0, //斜路高度
                // RightBottomBottom: 0,  //数字路高度
                CopyTableInfo: {},
                RoadList: {},
                ResultNumber: null,
                Timer: null,
                Timer1: null,
                ImgRootUrl: ''  //图片路径
            }
        },
        created() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.ComputedHeight();
                }, 100);
            });
        },
        watch: {
            'whdef': {
                handler(n, o) {
                    if (n === 0) return;
                    this.CopyWhdef = n;
                    clearTimeout(this.Timer);
                    this.Timer = setTimeout(() => {
                        if (this.whdef === this.CopyWhdef) this.ComputedHeight();
                    }, 1000);
                }
            },
            'TableInfo': {
                handler(n, o) {
                    if (this.ToString(n) === this.ToString(o)) return;  //防止其他桌子更新 当前卓也更新
                    this.ImgRootUrl = n.type;
                    this.CopyTableInfo = this.Clone(n);
                    if (this.ToString(n) !== this.ToString(o) && n.change === true) {
                        this.RoadList = n.transForm;
                        this.ResultNumber = this.RoadList['resultNumber'];
                    }
                }, deep: true, immediate: true
            },
        },
        methods: {
            ComputedHeight() {
                this.LeftH = this.$refs['left'].clientHeight;
                this.RightTopH = this.$refs["rightTop"].clientHeight;
                this.LeftBottomTop = this.$refs["leftBottomTop"].clientHeight;
                // this.LeftBottomBottom = this.$refs["leftBottomBottom"].clientHeight;
                // this.RightBottomTop = this.$refs["RightBottomTop"].clientHeight;
                // this.RightBottomBottom = this.$refs["RightBottomBottom"].clientHeight;
            }
        }
    }
</script>

<style lang="less">
    .opacity0 {
        opacity: 0;
    }

    .MultipleLZBaccarat {
        height: 100%;
        padding: .03rem;
        transition: .3s;

        .lz {
            .left-box {
                width: 50%;
            }

            .right-box {
                .right-top {
                    height: 50%;
                }

                .bottomLeft {
                    width: 50%;
                }
            }
        }
    }
</style>
